.box {
    display: flex;
    align-items: center;
    justify-content: center;
}

.star {
    margin-left: 25px;
    width: 30px;
    &:first-child {
        margin-left: 0;
    }

    input {
        visibility: hidden;
        position: absolute;
    }

    label {
        cursor: pointer;
        display: block;
    }
}