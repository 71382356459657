@import "@styles/settings.scss";

.notification {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.icon {
    path {
        fill: #1F1F1F;
    }
}

.circle {
    position: absolute;
    top: 3px;
    right: 3px;
    width: 12px;
    height: 12px;
    background-color: #FF0000;
    border-radius: 50%;
    transform: translate(50%, -50%);
}

.noComments {
    padding: 1rem;
    display: flex;
    justify-content: center;

    span {
        width: 70%;
        text-align: center;
    }
}

@media(max-width: $mobileMaxWidth) {
    .notification{
        // padding-right: 10px;

        button {
            width: 25px;
        }
    }

    .circle {
        margin-right: 10px;
    }
}
