@import "@styles/settings";

.list {
    margin-top: 25px;
    display: flex;
    justify-content: space-around;
}

.item {
    margin-bottom: 15px;
    display: grid;
    grid-template-rows: auto auto;
    justify-content: center;
    align-items: center;
    gap: 44px;
    width: 25%;
    background-color: #fff;
    filter: drop-shadow(0px 0px 4px rgba(0, 0, 0, 0.15));
    border-radius: 10px;
    padding-top: 26px;
    padding-bottom: 90px;
    padding-left: 6px;
    padding-right: 6px;

    .image_container {
        justify-self: center;
        align-self: center;
    }

    .content {
        color: #191E39;
        text-align: center;
        font-size: 18px;
        font-style: normal;
        font-weight: 600;
        line-height: 150%; /* 27px */
        letter-spacing: 0.36px;
    }
}

@media screen and (max-width: 1150px) {
    .item {
        width: 30%;
    }
}

@media screen and (max-width: 834px) {
    .list {
        display: grid;
        grid-template-rows: repeat(3, 1fr);
        grid-template-columns: 1fr;
    }
    
    .item {
        display: grid;
        grid-template-columns: 0.5fr 2.5fr;
        grid-template-rows: 1fr;
        width: 100%;
        padding-left: 16px;
        padding-top: 6px;
        padding-bottom: 6px;
        gap: 1em;

        .content {
            text-align: left;
            font-size: 14px;
            font-style: normal;
            font-weight: 600;
            line-height: 140%; /* 19.6px */
            letter-spacing: 0.56px;
        }
    }
}
