@import url('~/app/styles/_settings.scss');

.gallery {
    display: flex;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(#000, .5);
    z-index: 1000;
    padding-left: 15px;
    padding-right: 15px;
    overflow: hidden;

    @media (max-width: 600px) {
        padding-left: 0;
        padding-right: 0;
        background-color: #000;
    }
}

.slider {
    position: relative;
    background-color: #000;
    margin: auto;
    margin-top: 50svh;
    translate: 0 -50%;
    width: fit-content;
    height: fit-content;
    padding: 2rem;
    padding-inline: 3rem;
    border-radius: 10px;

    img {
        border-radius: 10px;
    }
}

.button {
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    border: none;
    background: none;
    cursor: pointer;
    width: 40px;
    height: 40px;
    color: #FFF;
    -webkit-text-fill-color: #FFF;
    border-radius: 50%;
    top: 50%;
    translate: 0 -50%;

    @media screen and (max-width: 600px) {
        visibility: visible !important;
    }
}

.list {
    position: relative;
    margin-top: 1rem;
    display: flex;
    max-width: 350px;
    overflow-x: hidden;

    &_inner {
        display: flex;
        transition: all .5s ease;
    }
}

.preview {
    display: block;
    @media screen and (max-width: 600px) {
        display: none;
    }
}

.close {
    position: fixed;
    top: 1vh;
    right: 1vh;
    width: 40px;
    height: 40px;
    border: none;
    background: none;
    cursor: pointer;
    z-index: 2000;

    @media screen and (max-width: 600px) {
        color: #FFF;
    }
}

.skeleton {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: #000;
    z-index: 1000;
    border-radius: 10px;
    display: grid;
    grid-template-rows: 3fr 1fr;
    gap: .5rem;
    padding: 2rem;

    div {
        background-color: rgb(43, 32, 32);
        border-radius: 30px;
        animation: skeleton 1s ease infinite;
    }

    @media screen and (max-width: 600px) {
        display: none;
    }
}

@keyframes skeleton {
    0% {
        background-color: rgb(43, 32, 32);
    }
    100% {
        background-color: rgb(56, 50, 50);
    }
}