@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@400;500;600;700&family=Roboto:wght@500&display=swap");
@import "reset";
@import "settings";
@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    font-family: "Montserrat", sans-serif;
    font-size: 16px;
    line-height: 1.3;
    color: #1D252E;
    background: #fff;
}

*::-webkit-scrollbar {
    width: 5px;
    /* width of the entire scrollbar */
    height: 5px;
}

* {
    scrollbar-color: rgb(81, 81, 81, 0.5) transparent;
    scrollbar-width: thin;
}

*::-webkit-scrollbar-track {
    background: transparent;
}

*::-webkit-scrollbar-thumb {
    background-color: rgb(81, 81, 81, 0.5);
}

.container {
    width: 100%;
    max-width: 1158px;
    margin-left: auto;
    margin-right: auto;
    padding-left: 15px;
    padding-right: 15px;
    position: relative;
}

@media (max-width: $tabletMaxWidth) {
    .container {
        max-width: 710px;
    }

    .button-back-container {
        margin-top: 60px;
    }
}

/*
    Helpers classes
*/

.text-center {
    text-align: center;
}

.text-right {
    text-align: right;
}

.button-reset {
    border: none;
    background: none;
    padding: 0;
}

.desktop-hidden {
    display: none;
}

@media (max-width: $tabletMaxWidth) {
    .tablet-hidden {
        display: none;
    }
}

@media (max-width: $mobileMaxWidth) {
    .mobile-hidden {
        display: none;
    }
}


/*
    Buttons
*/

.button {
    display: inline-flex;
    height: 56px;
    align-items: center;
    justify-content: center;
    text-align: center;
    text-decoration: none;
    font-size: 16px;
    font-weight: 700;
    cursor: pointer;
    border: none;
    border-radius: 8px;

    &.button-bordered {
        border: 2px solid #0084ca;
        background-color: #fff;
        color: #0084ca;
        padding: 0 40px;
        border-radius: 56px;
        &:hover {
            border-color: darken(#0084CA, 5%);
        }
        &:active {
            border-color: darken(#0084CA, 10%);
        }
        &:disabled {
            border-color: #c2c2c2;
            color: #c2c2c2;
            &:hover {
                border-color: #c2c2c2;
            }
            &:active {
                border-color: #c2c2c2;
            }
        }
    }

    &.long {
        min-width: 202px;
    }

    &.button-big {
        background-color: #0084ca;
        color: #fff;
        padding: 0 35px;
        height: 100px;
        font-size: 24px;
        font-weight: 500;
        &.button-empty {
            border: 2px solid #0084ca;
            color: #0084CA;
            background-color: transparent;
            
            &:hover {
                color: #fff;
            }
        }
        &:hover {
            background-color: darken(#0084CA, 5%);
        }
        &:active {
            background-color: darken(#0084CA, 10%);
        }
        &:disabled {
            background-color: #c2c2c2;
            cursor: default;
            &:hover {
                background-color: #c2c2c2;
            }
            &:active {
                background-color: #c2c2c2;
            }
        }
    }

    &.button-fill {
        background-color: #0084ca;
        color: #fff;
        padding: 0 40px;
        border-radius: 56px;
        &:hover {
            background-color: darken(#0084CA, 5%);
        }
        &:active {
            background-color: darken(#0084CA, 10%);
        }
        &:disabled {
            background-color: #C2C2C2;
            cursor: default;
            &:hover {
                background-color: #C2C2C2;
            }
            &:active {
                background-color: #C2C2C2;
            }
        }
    }

    &.button-small {
        height: 37px;
        font-size: 14px;
        font-weight: 400;
        padding: 0 10px;
    }

    &.button-icon {
        padding: 0 25px 0 10px;
        svg {
            margin-right: 8px;
        }
    }

    &.button-great {
        width: 260px;
        height: 120px;
        border: 3px solid #0084CA;
        color: #0084CA;
        padding: 15px;
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
        font-weight: 700;
        font-size: 24px;
        transition: .3s;
        &:hover {
            background-color: darken(#0084CA, 5%);
            color: white;
        }
        &:active {
            background-color: darken(#0084CA, 10%);
        }
        &:disabled {
            border: 3px solid #C2C2C2;
            cursor: default;
            &:hover {
                background-color: #C2C2C2;
                color: white;
            }
            &:active {
                background-color: #C2C2C2;
            }
        }
    }
}

.button-more {
    height: 100px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    text-align: left;
    font-weight: 500;
    font-size: 28px;
    color: #0084CA;
    border: 2px solid #0084CA;
    border-radius: 8px;
    padding: 0 75px 0 35px;
    background: none;
   /*  text-decoration: underline; */
}

.button-link {
    border: none;
    background: none;
    font-weight: 700;
    color: #0084CA;
    text-decoration: none;
    padding: 20px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-size: 16px;
    svg {
        margin-left: 8px;
    }
}

.button-back {
    display: inline-flex;
    align-items: center;
    color: #0084CA;
    font-size: 22px;
    text-decoration: none;
    padding: 20px 0 10px;
    svg {
        margin-right: 8px;
    }

    &.isBold {
        font-size: 20px;
        font-weight: 700;
    }
}

.button-back-side {
    position: absolute;
    top: 0;
    left: 5px;
    .button-back {
        padding: 10px 0;
    }
}

@media (max-width: $mobileMaxWidth) {
    .button {
        &.button-big {
            font-size: 18px;
            font-weight: 600;
            width: 100%;
        }
        &.button-icon {
            width: 38px;
            height: 38px;
            border-radius: 50%;
            padding: 0;
            span {
                display: none;
            }
            svg {
                margin-right: 0;
            }
        }
    }

    .button-more {
        padding: 0 15px;
        font-size: 20px;
        svg {
            margin-left: 50px; 
        }
    }

    .button-back-side {
        position: static;
        margin: 0 auto 40px;
    }
    
}