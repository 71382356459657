.container {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    position: relative;
}

.dropdown {
    position: absolute;
    top: 2rem;
    width: 377px;
    border-radius: 10px;
    z-index: 10000;
    background-color: #fff;
    left: 0;
    opacity: 0;
    visibility: hidden;
    transform: translateY(-10px);
    transition: opacity 0.3s, visibility 0.3s, transform 0.3s;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    display: flex;
    flex-direction: column;
    max-height: 100vh;
    overflow-y: auto;

    &.active {
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
    }
}

.head {
    display: none;
}

.menuContainer {
    padding: 1rem;
}

.menuItem {
    cursor: pointer;
    padding-bottom: 1rem;
    padding-top: 1rem;
    border-bottom: 1px solid #E2E2E2;

    &:last-child {
        border-bottom: none;
    }

    &:hover {
        font-weight: 500;
        transition: all 0.3s;
    }
}

@media screen and (max-width: 425px) {
    .dropdown {
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        width: 100%;
        height: 100%;
        margin: 0;
        border-radius: 0
    }

    .head {
        display: flex;
        justify-content: flex-end;
        padding: 1rem;
    }
}