@import "@styles/settings";

.header {
    display: flex;
    flex-wrap: wrap;
    align-items: flex-start;
    justify-content: space-between;
}

.title {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 44px;
    font-weight: 400;

    button {
        margin-left: 30px;
    }

    span {
        color: #0084CA;
        margin-left: 10px;
        white-space: nowrap;
    }
}

.info {
    margin-left: auto;
    text-align: right;
    font-size: 22px;

    p {
        margin-top: 10px;
    }
}

.mobile {
    display: none;
}

.button {
    height: 62px;
    border: 2px solid #0084CA;
    color: #0084CA;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    border-radius: 8px;
    font-size: 20px;
    font-weight: 500;
    padding: 0 27px;
    background: none;
    /*text-decoration: underline;*/
    cursor: pointer;
}

.description {
    width: 100%;
    margin: 35px 0 20px;
    font-size: 22px;
    display: flex;
    align-items: center;
    line-height: 1.5;

    svg {
        width: 22px;
        margin-right: 10px;
    }
}

.barcode {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

@media(max-width: $tabletMaxWidth) {
    .button {
        display: flex;
    }

    .description {
        margin: 15px auto 25px;
    }

    .withInfo {
        .title span {
            display: block;
            margin-left: 0;
        }

        .description {
            display: flex;

            button {
                margin: 10px 0px 0px 0px;
            }
        }
    }

    .desktop {
        display: none;
    }

    .mobile {
        display: block;

        >div {
            margin-top: 10px;
        }
    }
}

@media(max-width: $mobileMaxWidth) {
    .title {
        order: 1;
        font-size: 20px;
    }

    .button {
        order: 3;
        font-size: 20px;
        margin: 0 auto 24px;
    }

    .header {
        align-items: center;
    }

    .info {
        order: 2;
    }

    .mobile {
        >div {
            margin-top: 4px;

            &:first-child {
                margin-top: 0;
            }
        }
    }

    .description {
        order: 2;
        font-size: 12px;
        margin: 10px 0 24px;
    }

    .barcode {
        margin-bottom: 80px;
    }
}