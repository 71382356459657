.button {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    background: #DCF3FF;
    border-radius: 0px 0px 10px 10px;
    height: 60px;

    &:hover {
        background: darken(#DCF3FF, 5%);
    }
}
