@import "@styles/settings";

.box {
    text-align: center;
    margin: 20px auto;
}

.title {
    font-size: 45px;
    font-weight: 400;
    margin-bottom: 15px;
}

.text {
    font-size: 24px;
    max-width: 800px;
    margin-left: auto;
    margin-right: auto;
    margin-bottom: 55px;
}

.agreement {
    max-width: 570px;
    margin: 35px auto 35px;
    text-align: left;
}

.checkbox {
    position: relative;
    display: flex;
    flex-direction: column;
    row-gap: 1em;

    input {
        visibility: hidden;
        opacity: 0;
        position: absolute;
    }
    label {
        font-size: 18px;
        display: flex;
        align-items: center;
        cursor: pointer;
        user-select: none;
    }
    span {
        min-width: 30px;
        height: 30px;
        border: 2px solid #0084CA;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 3px;
        margin-right: 25px;
        svg {
            visibility: hidden;
            opacity: 0;
            transition: .3s;
        }
    }
    input:checked + label span svg {
        opacity: 1;
        visibility: visible;
    }
}

.buttons {
    margin-top: 33px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.buttonConfirm {
    min-width: 305px;
}

.contract {
    text-align: center;
    iframe, img {
        margin: 0 auto;
        width: 100%;
        max-width: 935px;
    }
    iframe {
        height: 90vh;
    }
    a {
        display: none;
    }
}

.imageBox {
    border: 2px solid #cecece;
    max-width: 935px;
    margin: auto;
    border-radius: 8px;
    overflow: hidden;
}

@media(max-width: $tabletMaxWidth) {
    .title {
        margin-bottom: 20px;
    }
    .text {
        margin-bottom: 20px;
    }
    .agreement {
        margin-bottom: 70px;
    }
    .contract {
        iframe {
            display: none;
        }
        a {
            display: flex;
        }
    }
}

@media(max-width: $mobileMaxWidth) {
    .box {
        margin: 0;
    }
    .title {
        font-weight: 500;
        font-size: 24px;
        margin-bottom: 32px;
    }
    .text {
        font-size: 20px;
        margin-bottom: 50px;
    }
    .agreement {
        margin-bottom: 50px;
    }
    .checkbox label {
        font-size: 16px;
    }
    .buttons {
        display: block;
        margin-top: 24px;
        button {
            display: block;
            width: 100%;
            margin-top: 8px;
        }
    }
    .buttonConfirm {
        min-width: 100%;
    }
}