@import "@styles/settings";

.box {
    text-align: center;
}

.title {
    font-size: 24px;
    font-weight: 400;
    margin-bottom: 15px;
}

.field {
    margin-bottom: 35px;
    margin-top: 40px;
    label {
        margin-bottom: 15px;
        display: block;
        font-size: 24px;
    }
    textarea {
        display: block;
        width: 100%;
        max-width: 848px;
        height: 186px;
        min-height: 186px;
        margin-left: auto;
        margin-right: auto;
        background: #fafafa;
        border: 1px solid #bbbbbb;
        border-radius: 8px;
        padding: 15px;
    }
}

@media(max-width: $tabletMaxWidth) {
    .title {
        margin-bottom: 35px;
    }
    .field {
        margin-top: 35px;
        label {
            margin-bottom: 35px;
        }
    }
}

@media(max-width: $mobileMaxWidth) {
    .title {
        margin-bottom: 12px;
    }
    .field {
        margin-top: 50px;
        label {
            margin-bottom: 40px;
        }
    }
}