@import "@styles/settings";

.banner {
    margin: 0 auto 35px;
}

.buttons {
    margin: 35px auto;
    width: 100%;
    max-width: 345px;

    button, a {
        width: 100%;
    }
}

.back {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    a {
        background-color: #fff;
        height: 100px;
        display: block;
        display: flex;
        align-items: center;
        justify-content: flex-start;
        padding-left: 25px;
    }
}

@media(max-width: $mobileMaxWidth) {
    .box {
        margin-left: -15px;
        margin-right: -15px;
    }
    .buttons {
        padding-left: 15px;
        padding-right: 15px;
        margin-top: 40px;
    }

    .back {
        a {
            height: 70px;
            padding-left: 0;
        }
    }
}