@import "@styles/settings";

.overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 10000;
    background-color: rgba(#000, .5);
    padding: 0 15px;
    overflow: auto;
}

.content {
    background-color: #fff;
    width: 100%;
    max-width: 965px;
    margin: 115px auto;
    padding: 40px 40px 95px;
    position: relative;
    text-align: center;
    display: flex;
    justify-content: center;

    &.isSmall {
        max-width: 750px;
        padding-bottom: 60px;
    }
}

.title {
    font-size: 42px;
    font-weight: 600;
    max-width: 580px;
    margin: 0 auto 24px;
}

.text {
    max-width: 620px;
    margin: 0 auto 24px;
    font-size: 26px;
    line-height: 1.2;
}

.buttons {
    margin: 45px auto 0;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    button, a {
        margin-left: 45px;
        min-width: 255px;
        margin-top: 12px;
        &:first-child {
            margin-left: 0;
        }
    }
}

.close {
    position: absolute;
    top: 0;
    right: 0;
    margin: 15px 10px;
    width: 35px;
    height: 35px;
    border: none;
    background: none;
    cursor: pointer;
    transition: .3s;
    &:hover {
        opacity: .7;
    }
}

.mobile {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    padding-top: 2em;
    height: 100%;
    overflow-y: scroll;
    z-index: 10000;
    background-color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
}

@media(max-width: $tabletMaxWidth) {
    .content {
        max-width: 680px !important;
        padding-bottom: 65px;
        padding-left: 25px;
        padding-right: 25px;
    }

    .title {
        font-size: 36px;
        margin-bottom: 12px;
    }

    .text {
        font-size: 20px;
        margin-bottom: 12px;
    }

    .buttons {
        margin-top: 50px;
    }
}

@media(max-width: 650px) {
    .content {
        margin-top: 80px;
        padding: 70px 25px 40px;
    }
    .title {
        font-size: 20px;
        margin-bottom: 32px;
    }
    .buttons {
        display: block;
        margin-top: 35px;
        button, a {
            display: block;
            margin: 12px auto 0 !important;
        }
    }
}