@import "@styles/settings";

/*
    Layout
*/

.box {
    display: flex;
    margin-left: -30px;
    .list {
        margin-left: 30px;
        width: calc(50% - 30px);
    }

    &.full {
        display: block;
        margin-left: 0;
        .list {
            margin-top: 40px;
            margin-left: 0;
            width: 100%;
        }
    }
}

.buttons {
    text-align: center;
    margin-top: 24px;
}

@media(max-width: $tabletMaxWidth) {
    .box {
        display: block;
        margin-left: 0;
        .list {
            margin-left: 0;
            width: 100%;
        }
    }
    .buttons {
        margin-top: 40px;
    }
}

@media(max-width: $mobileMaxWidth) {
    .buttons {
        margin-top: 32px;
    }
}

/*
    Info
*/

.list {
    border: 2px solid #CECECE;
    list-style: none;
    border-radius: 8px;
    padding: 25px 15px;
    min-height: 302px;
    margin-top: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    font-size: 22px;

    li {
        flex: 1;
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        padding: 12px 25px 12px 15px;
        height: fit-content;
        border-top: 1px solid #CECECE;
        &:first-child {
            border-top: none;
        }
    }
}

.list.total {
    padding-top: 10px;
    padding-bottom: 10px;
}

.key {
    width: 55%;
}

.value {
    display: inline-flex;
    align-items: center;
    justify-content: flex-end;
    width: 45%;
    img {
        margin-right: 10px;
        max-width: 36px;
    }
    small {
        font-size: 18px;
    }
}

.helpText {
    width: 100%;
    font-size: 16px;
    font-weight: 500;
    color: #0084CA;
    margin-top: 4px;
}

.helpLink {
    display: flex;
    color: #0084CA;
    font-weight: 700;
    font-size: 16px;
    text-align: center;
    width: 100%;
    margin-top: 24px;
}

.list.withButton {
    padding-top: 20px;
    padding-bottom: 15px;
    min-height: 403px;
}

.total.withButton {
    > li {
        padding-left: 20px;
        padding-right: 10px;
    }
}

li.button {
    padding: 0 !important;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    margin-top: 40px;
    border-top: none;
}

li.withSub {
    padding-top: 0;
    padding-bottom: 10px;
}

.sub {
    width: 100%;
    font-size: 14px;
    &::after {
        content: "";
        display: block;
        max-width: 390px;
        margin: 10px auto 5px;
        border-top: 1px solid #CECECE;
    }
    li {
        padding: 0;
        border: none;
        margin-top: 8px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        &:first-child {
            padding: 0;
        }
    }
}

.payment_text{
    padding-left: 20px;
    padding-right: 10px;
    font-size: 18px;
}

/*
    Tile
*/

.totalTile {
    list-style: none;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    text-align: center;
    margin: 40px auto;
    li {
        width: 280px;
        margin-left: 70px;
        padding-left: 70px;
        border-left: 1px solid #000;
        &:first-child {
            margin-left: 0;
            padding-left: 0;
            border-left: none;
        }
    }

    span, b {
        display: block;
    }

    span {
        color: #8D8D8D;
        font-size: 22px;
    }

    b {
        font-size: 32px;
        font-weight: 500;
        color: #3E3E3E;
        margin-top: 15px;
    }
}

/*
    Значок вопроса и блок доставки
*/

.deliveryInfo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    margin-bottom: 12px;
    position: relative;
}

.deliveryTitle {
    font-size: 22px;
    font-weight: 500;
}

.questionContainer {
    position: relative;
}

.questionIcon {
    background: white;
    color: #0084CA;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    font-weight: bold;
}

.modalContent {
    position: absolute;
    top: 22px;
    right: 0;
    background: white;
    padding: 12px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    z-index: 100;
    width: 200px;
    font-size: 14px;
    color: #333;

    p {
        margin: 0 0 8px 0;
        &:last-child {
            margin-bottom: 0;
        }
    }
}

.closeButton {
    position: absolute;
    top: 5px;
    right: 5px;
    background: none;
    border: none;
    font-size: 16px;
    cursor: pointer;
    color: #333;
}

.phoneLink {
    color: #0084CA;
    text-decoration: none;
    font-weight: bold;

    &:hover {
        text-decoration: underline;
    }
}

.deliveryHelp {
    display: flex;
    flex-direction: column;
    font-size: 16px;
    color: #0084CA;
    margin-bottom: 12px;
}

.deliveryButton {
    width: 100%;
    padding: 12px;
    background: #0084CA;
    color: white;
    border: none;
    border-radius: 4px;
    font-size: 16px;
    font-weight: bold;
    cursor: pointer;
}

@media(max-width: $tabletMaxWidth) {
    li.button {
        margin-top: 30px;
        margin-bottom: 15px;
    }

    .sub {
        &::after {
            max-width: 390px;
        }
    }

    .helpLink {
        display: block;
        margin-top: 15px;
    }
}

@media(max-width: $mobileMaxWidth) {
    .list {
        font-size: 16px;
        padding-bottom: 10px;
        padding-top: 10px;
        min-height: auto;
        > li {
            padding: 12px 0;
            align-items: center;
        }
    }

    .list.total {
        .value {
            justify-content: flex-end;
        }
    }

    .list.withButton {
        font-size: 18px;
        .value {
            justify-content: flex-end;
        }
    }

    .total.withButton {
        padding-top: 10px;
        padding-bottom: 20px;
        > li {
            padding-left: 0;
            padding-right: 0;
        }
    }

    .value {
        padding-left: 5px;
        justify-content: flex-start;
    }

    .helpLink {
        text-align: center;
        width: 100%;
        margin-top: 24px;
    }

    .status {
        font-size: 14px;
        font-weight: 500;
        small {
            font-size: 14px;
        }
        img {
            max-width: 25px;
        }
    }

    .sub {
        &::after {
            max-width: 100%;
            margin-top: 24px;
            margin-bottom: 15px;
        }
    }

    .totalTile {
        li {
            padding-left: 30px;
            margin-left: 30px;
        }
        span {
            font-size: 14px;
        }
        b {
            font-size: 26px;
        }
    }

    .payment_text{
        font-size: 16px;
        padding: 0%;
    }
}