*,
*::before,
*::after {
    -webkit-box-sizing: border-box;
    box-sizing: border-box;
}

body,
h1,
h2,
h3,
h4,
h5,
h6,
p,
ul,
ol,
li,
figure,
figcaption,
blockquote,
dl,
dd {
    margin: 0;
    padding: 0;
}

body {
    scroll-behavior: smooth;
    text-rendering: optimizeSpeed;
}

img {
    max-width: 100%;
    display: block;
    height: auto !important;
}

a {
    color: inherit;
}

input,
button,
textarea,
select {
    font: inherit;
}

input[type="number"]::-webkit-outer-spin-button,
input[type="number"]::-webkit-inner-spin-button {
    -webkit-appearance: none;
}

input[type="number"],
input[type="number"]:hover,
input[type="number"]:focus {
    appearance: none;
    -moz-appearance: textfield;
}