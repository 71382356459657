.comment_container {
    position: relative;
    display: grid;
    grid-template-columns: 1fr 3fr 1fr;
    border-top: 2px solid #CECECE;
    padding: 1rem;
    width: 100%;

    &:nth-child(2) {
        border-top: none;
    }
}

.new {
    &::before {
        position: absolute;
        content: '';
        width: 100%;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        z-index: 10;
        animation-name: disappear;
        animation-duration: 1s;
        animation-fill-mode: forwards;
        animation-timing-function: ease-in-out;
    }
}

.title {
    font-weight: 500;
    margin-bottom: .5rem;
    text-align: start;
}

.description {
    width: 100%;
    font-size: 14px;
    text-align: start;
}

.arrow {
    display: flex;
    justify-content: center;
    align-items: flex-start;
    padding-top: 20%;
    cursor: pointer;
    -webkit-tap-highlight-color: transparent;

    svg {
        width: 20px;
        height: 20px;

        path {
            fill: #0084CA;
        }
    }

    &_rotate {
        transform: rotate(180deg);
        align-items: flex-end;
        padding-bottom: 20%;
    }
}

.image {
    justify-self: center;
}

@keyframes disappear {
    from {
        opacity: 0.5;
        background-color: #83ca00;
    }

    to {
        opacity: 0;
        display: none;
    }
}