@import "settings";

.slick-list,
.slick-slider,
.slick-track {
    position: relative;
    display: flex;
}
.slick-loading .slick-slide,
.slick-loading .slick-track {
    visibility: hidden;
}
.slick-slider {
    box-sizing: border-box;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    -khtml-user-select: none;
    -ms-touch-action: pan-y;
    touch-action: pan-y;
    -webkit-tap-highlight-color: transparent;
}
.slick-list {
    overflow: hidden;
    margin: 0;
    padding: 10px;
}
.slick-list:focus {
    outline: 0;
}
.slick-list.dragging {
    cursor: pointer;
    cursor: hand;
}
.slick-slider .slick-list,
.slick-slider .slick-track {
    -webkit-transform: translate3d(0, 0, 0);
    -moz-transform: translate3d(0, 0, 0);
    -ms-transform: translate3d(0, 0, 0);
    -o-transform: translate3d(0, 0, 0);
    transform: translate3d(0, 0, 0);
}
.slick-track {
    top: 0;
    left: 0;
}
.slick-track:after,
.slick-track:before {
    display: table;
    content: "";
}
.slick-track:after {
    clear: both;
}
.slick-slide {
    display: none;
    float: left;
    height: 100%;
    min-height: 1px;
}
[dir="rtl"] .slick-slide {
    float: right;
}
.slick-slide img {
    display: block;
}
.slick-slide.slick-loading img {
    display: none;
}
.slick-slide.dragging img {
    pointer-events: none;
}
.slick-initialized .slick-slide {
    display: flex;
}
.slick-vertical .slick-slide {
    display: block;
    height: auto;
    border: 1px solid transparent;
}
.slick-arrow.slick-hidden {
    display: none;
}

/*
    Custom
*/

.stocks-slider {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    
    .slick-list {
        // border: 1px solid red;
        width: 100%;
        order: 1;
        margin-bottom: 60px;
        padding: 10px 0;
    }
    
    .slick-slide {
        padding: 0 15px;
    }
}



@media (max-width: $tabletMaxWidth) {
    .stocks-slider {
        .slick-list {
            margin-bottom: 35px;
        }
        .slick-slide {
            padding-left: 22px;
        }
    }
}

@media (max-width: $mobileMaxWidth) {
    .stocks-slider {
        .slick-list {
            margin-bottom: 25px;
        }
        .slick-slide {
            padding-left: 0;
        }
    }

    .slick-slide {
        justify-content: center;
    }
}
.stocks-slider {
    .slick-prev {
        order: 2;
    }
    
    .slick-next {
        order: 3;
    }
    
    .slick-prev,
    .slick-next {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        border: 2px solid #0084ca;
        background: none;
        cursor: pointer;
        text-indent: -1000px;
        overflow: hidden;
        white-space: nowrap;
        background: url(../../assets/icons/arrow-left.svg) no-repeat center;
    }
    
    .slick-prev {
        margin-right: 40px;
    }
    
    .slick-next {
        transform: rotate(-180deg);
    }
    
    .slick-disabled {
        opacity: 0.3;
    }
}

.gallery-slider-preview {
    margin-left: -9px;
    .slick-slide {
        padding-left: 9px;
    }
    .slick-current {
        img {
            border: 1px solid #fff;
        }
    }
}