.box {
    padding: 50px 0;
    overflow-x: hidden;

    &.zeroPadding {
        padding: 0;
    }

    &.smallPadding {
        padding: 25px 0;
    }
}

.title {
    font-size: 44px;
    font-weight: 400;
    display: flex;
    gap: 25px;
    position: relative;

    span {
        color: #0084ca;
    }
}

.close {
    position: absolute;
    top: 5px;
    right: 15px;
    cursor: pointer;
    width: 20px;
    height: 20px;
    background-image: url('../../assets/icons/close.svg');
    background-repeat: no-repeat;
}

.title_with_close {
    padding-right: 50px;
}

@media screen and (max-width: 834px) {
    .box {
        padding-top: 34px;
    }

    .title {
        font-size: 27px;
        justify-content: center;

        img {
            display: none;
        }
    }

    .with_icon {
        margin-left: 0;
    }
}

@media screen and (max-width: 475px) {

    .box {
        padding-top: 65px;
    }
}