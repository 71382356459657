@import "@styles/settings";

.box {
    margin-bottom: 80px;
}

.table {
    margin: 35px auto;
    border-radius: 8px;
    overflow: hidden;
    list-style: none;
    border-collapse: collapse;
    width: 100%;
}

.labels {
    background-color: #E1E1E1;
    border-radius: 8px 8px 0 0;
    td {
        padding: 13px 18px;
        white-space: nowrap;
    }
}

.item {
    border-top: 2px solid #CECECE;
    &:first-child {
        border-top: none;
    }
    td {
        padding: 15px 18px;
    }
}

.colored {
    background-color: #FAFAFA;
}

.heading {
    max-width: 265px;
}

.title,
.description {
    .mobile {
        display: none;
    }
}

.title {
    font-size: 24px;
    display: flex;
    // gap: 1rem;
    flex-direction: column;
    align-items: center;
}

.description {
    font-weight: 500;
}

.data {
    font-size: 24px;
}

.mobileLabel {
    display: none;
}

.preview,
.mobilePreview {
    width: 144px;
    height: 168px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 8px;
    overflow: hidden;
    cursor: pointer;
}

.previewBox {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.mobilePreview {
    display: none;
}

.showMore {
    width: 32px;
    height: 32px;
    border-radius: 6px;
    background-color: #fff;
    border: none;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    right: 0;
    margin: 15px;
    display: none;
    cursor: pointer;
    svg {
        transition: .3s;
    }
}

.bt {
    border-top: 2px solid #CECECE;
}

.btt {
    border-top: 1px solid #CECECE,
}

.tablet_child_item {
    text-align: center;
    font-size: 17px;
    border-bottom: 1px solid #CECECE;
    line-height: 150%;
    position: relative;

    td {
        padding-top: 18px;
        padding-bottom: 24px;
        padding-left: 15px;
    }
}

.tablet_parent_item {
    text-align: center;
    font-size: 21px;
    font-weight: 600;
    border-top: 2px solid #CECECE;
    border-bottom: 1px solid #CECECE;
    line-height: 150%;
    position: relative;

    td {
        padding-top: 8px;
        padding-bottom: 5px;
    }
}

.photo_button {
    white-space: break-spaces;
    font-size: 14px;
    line-height: 150%;
    text-decoration: underline;
    color: #0084CA;
    font-weight: 600;
}

.mobile_child_item {
    text-align: left;
    font-size: 17px;
    border-bottom: 1px solid #CECECE;
    line-height: 150%;
    font-weight: 600;
    position: relative;
}

.mobile_child_item {
    td {
        padding: 37px 14px 33px;
    }
}

.mobile_parent_item {
    text-align: center;
    font-size: 23px;
    font-weight: 600;
    border-top: 2px solid #CECECE;
    border-bottom: 1px solid #CECECE;
    line-height: 150%;

    td {
        padding-top: 12px;
        padding-bottom: 12px;

        button {
            float: right;
            padding-top: 50%;
            translate: 0 -40%;
            margin-right: 10px;
        }
    }
}

thead tr, tbody {
    border: 2px solid #DDD;
}

.isOpen {
    .showMore svg {
        transform: rotate(180deg);
    }
}

.bold {
    font-weight: 500;
}

.parentItemPreview {
    width: 144px;
    height: 168px;
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 8px;
    overflow: hidden;
    cursor: pointer;
}

@media(max-width: $tabletMaxWidth) {
    .table {
        td.previewBox {
            display: none;
        }
    }
    .item {
        padding: 0;
        padding-bottom: 24px;
        border-top: none;
        flex-wrap: wrap;
    }
    .heading {
        max-width: 100%;
        width: 100%;
        display: flex;
        align-items: flex-start;
        flex-wrap: wrap;
        position: relative;
        background-color: #EBEBEB;
        border-radius: 8px;
        padding: 15px 65px 18px 15px !important;
    }
    .showMore {
        display: flex;
    }
    .title,
    .description {
        .mobile {
            display: block;
        }
        .desktop {
            display: none;
        }
    }
    .title {
        font-size: 16px;
        font-weight: 500;
        max-width: calc(100% - 160px);
        padding-right: 30px;
    }
    .description {
        width: 100%;
        margin-top: 12px;
        max-width: 600px;
        font-size: 14px;
        display: none;
    }
    .preview {
        display: none;
    }
    .mobilePreview {
        margin-right: 24px;
        width: 136px;
        height: 156px;
        display: block;
    }
    .data {
        width: 25%;
        flex-direction: column;
    }
    .mobileLabel {
        display: block;
        font-size: 16px;
        margin-bottom: 4px;
    }

    .isOpen {
        .data,
        .description {
            display: flex !important;
        }
    }

    tbody {
        border: 2px solid #DDD
    }

    .item {
        td {
            padding-top: 24px;
            padding-bottom: 24px;
        }
    }
}

@media(max-width: $mobileMaxWidth) {
    .item {
        td {
            padding-bottom: 8px;
            padding-top: 8px;
            padding-left: 15px;
            padding-right: 15px;
            font-weight: 500;
        }

        td:nth-child(2) {
            text-align: right;
        }
    }
    .item:last-child {
        border-bottom: 2px solid #DDD;  
    }
    .isOpen {
        padding-bottom: 15px;
    }
    .mobilePreview {
        width: 100px;
        height: 115px;
        margin-right: 15px;
    }
    .heading {
        padding: 15px !important;
    }
    .title {
        padding-right: 10px;
        max-width: calc(100% - 115px);
    }
    .showMore {
        margin: 4px;
    }
    .data {
        width: 100%;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        font-size: 14px;
        padding-top: 7px !important;
        padding-bottom: 7px !important;
    }
    .mobileLabel {
        font-size: 14px;
    }

    tbody {
        border: 2px solid #DDD
    }
}