@import "@styles/settings";

.list {
    margin-left: -15px;
    margin-right: -15px;
    margin-top: 65px;
}

.box {
    background: #fff;
    box-shadow: 0 4px 14px rgba(124, 128, 132, 0.1);
    border-radius: 8px;
    max-height: 600px;
    height: 100%;
    overflow: auto;

    &:first-child {
        margin-left: 0;
    }
}

a.box {
    text-decoration: none;
    display: block;
}

.image {
    width: 100%;
    height: 199px;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    overflow: hidden;
    border-radius: 8px 8px 0 0;
    position: relative;
}

.info {
    padding: 15px 15px 25px;
    overflow: auto;
}

.dates {
    font-size: 14px;
    color: #0048a6;
    margin-bottom: 15px;
}

.title {
    font-size: 22px;
    font-weight: 700;
    margin-bottom: 8px;
}

@media (max-width: $tabletMaxWidth) {
    .wrapper {
        overflow: visible;
    }
    .list {
        margin-left: -56px;
        width: 100vw;
        margin-top: 55px;
    }
    .box {
        box-shadow: none;
        max-width: 600px;
    }
    .info {
        padding: 15px 30px 60px 30px;
    }
    .dates {
        margin-bottom: 45px;
    }
    .title {
        margin-bottom: 25px;
    }
}

@media (max-width: $mobileMaxWidth) {
    .wrapper {
        overflow: hidden;
    }
    .list {
        margin-left: 0;
        width: 100%;
    }
    .box {
        box-shadow: none;
        max-width: 100%;
    }
    .info {
        padding: 30px 15px 60px 25px;
    }
    .dates {
        margin-bottom: 35px;
    }
}
