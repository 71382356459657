.idram {
    &_background {
        background-image: url(../../../assets/images/idram.png);
    }

    background-position: center;
    background-repeat: no-repeat;
    padding-inline: 1rem;
    background-size: 80%;
}

.arca {
    &_background {
        background-image: url(../../../assets/images/arca.png);
    }
    
    background-position: center;
    background-repeat: no-repeat;
    padding-inline: 1rem;
    background-size: 80%;
    
    &:hover {
        background-color: #fff !important;
        scale: 1.01;
    }
}
